@if (viewMode === 'popover') {
  <div class="popover-body" style="width: 100%">
    <div class="modal-header">
      @if (model.allowedTypes.length > 1) {
        <chitin-select-old
          [clearable]="false"
          placeholder="type"
          class="flex-grow-1"
          [items]="typeOptions"
          [value]="model.type"
          (valueChange)="onTypeChanged($event)"
          [appendTo]="undefined"
          [autoWidth]="false"
          style="z-index: 1201 !important"
        />
      }
    </div>
    <div class="modal-body d-flex gap-2 align-items-end">
      <div class="flex-grow-1">
        @if (model.type === 'range') {
          <chitin-range-input
            #focusableInputField
            [clearable]="true"
            size="small"
            [value]="$any(model.value)"
            (valueChange)="onValueChanged($event)"
          />
        } @else if (model.type === 'in') {
          <chitin-select-old
            #focusableInputField
            class="value-select"
            size="sm"
            (valueChange)="onValueChanged($event)"
            placeholder="Select options"
            [disableSelectOnEnter]="true"
            [groupItems]="modelHasGroupedItems"
            [expandSelectedGroupItems]="modelHasGroupedItems"
            [items]="model.options ?? []"
            [multiple]="model.multiple ?? true"
            [showSelectAll]="model.allowSelectAll ?? true"
            [maxNumOfItems]="model.maxNumOfOptions"
            [displayNumberOfSelected]="true"
            [appendTo]="undefined"
            [alwaysOpen]="true"
            [checkboxView]="model.multiple ?? true"
            [autoWidth]="true"
            [value]="$any(model.value)"
            [maxOneGroup]="model.maxOneGroup ?? false"
          />
        } @else if (model.type === 'monthDaySelect') {
          <chitin-select-old
            #focusableInputField
            class="value-select"
            size="sm"
            [items]="monthOptions()"
            [(value)]="selectedMonth"
            [appendTo]="undefined"
            [autoWidth]="true"
            placeholder="Month"
          />
          <chitin-select-old
            class="value-select"
            size="sm"
            [items]="dayOptions()"
            [(value)]="selectedDay"
            [appendTo]="undefined"
            [autoWidth]="true"
            [isDisabled]="selectedMonth() === null"
            placeholder="Day"
          />
        } @else if (['dateRange', 'datetimeRange'].includes(model.type)) {
          <chitin-daterange-picker
            size="small"
            placeholder="Select Date(s)"
            color="secondary"
            [addDateRangePresets]="['YTD']"
            [hideDateRangePresets]="['This Year']"
            [showLabelContextInfo]="true"
            [showTimezoneSelector]="model.type === 'datetimeRange'"
            [fullwidth]="true"
            [value]="$any(model.value)"
            (valueChange)="onValueChanged($event)"
            [selectedTimezone]="model.timezone"
            (selectedTimezoneChange)="onTimezoneChanged($event)"
          />
        } @else if (model.type === 'date') {
          <chitin-button-date-picker
            size="small"
            label="Select Date"
            [value]="$any(model.value)"
            (valueChange)="onValueChanged($event)"
            color="secondary"
          />
        } @else if (model.type === 'isOneOf') {
          <chitin-chips-input
            #focusableInputField
            size="small"
            [clearable]="false"
            placeholder="value1,value2,..."
            [value]="$any(model.value)"
            [emitValueTrigger]="chipsForceEmitTrigger.asObservable()"
            (emitValueTriggerFinished)="forceEmitFinished($event)"
            (valueChange)="onValueChanged($event)"
          />
        } @else if (
          !['range', 'dateRange', 'datetimeRange', 'exists', 'nexists', 'isOneOf', 'in', 'date', 'monthDaySelect'].includes(model.type)
        ) {
          <chitin-form-input
            #focusableInputField
            size="small"
            [clearable]="true"
            placeholder="value"
            [value]="$any(model.value)"
            (valueChange)="onValueChanged($event)"
          />
        }
      </div>
      @if (!model.required && ['date', 'dateRange', 'datetimeRange'].includes(model.type) && !!model.value) {
        <chitin-button size="small" color="secondary" label="CLEAR" (clicked)="clearClicked()" />
      }
      <chitin-button size="small" color="secondary" label="OK" (clicked)="okClicked()" />
    </div>
  </div>
} @else {
  <div class="d-flex gap-2">
    @if (model.allowedTypes.length > 1) {
      <chitin-select-old
        [clearable]="false"
        placeholder="type"
        [items]="typeOptions"
        [value]="model.type"
        (valueChange)="onTypeChanged($event)"
        [appendTo]="undefined"
        [autoWidth]="false"
        size="sm"
        style="min-width: 110px"
      />
    }
    @if (model.type === 'monthDaySelect') {
      <chitin-select-old
        class="value-select"
        size="sm"
        [items]="monthOptions()"
        [(value)]="selectedMonth"
        [appendTo]="undefined"
        [autoWidth]="true"
        placeholder="Month"
      />
      <chitin-select-old
        class="value-select"
        size="sm"
        [items]="dayOptions()"
        [(value)]="selectedDay"
        [appendTo]="undefined"
        [autoWidth]="true"
        [isDisabled]="selectedMonth() === null"
        placeholder="Day"
      />
    }
    @if (model.type === 'range') {
      <chitin-range-input
        [clearable]="false"
        size="small"
        [value]="$any(model.value)"
        (valueChange)="onValueChanged($event)"
        (keyup.enter)="okClicked()"
      />
    }
    @if (model.type === 'in') {
      <chitin-select-old
        class="value-select"
        size="sm"
        (valueChange)="onValueChanged($event)"
        placeholder="Select options"
        [groupItems]="modelHasGroupedItems"
        [items]="model.options ?? []"
        [multiple]="model.multiple ?? true"
        [showSelectAll]="model.allowSelectAll ?? true"
        [maxNumOfItems]="model.maxNumOfOptions"
        [displayNumberOfSelected]="true"
        [appendTo]="undefined"
        [checkboxView]="model.multiple ?? true"
        [autoWidth]="true"
        [maxOneGroup]="model.maxOneGroup ?? false"
        [value]="$any(model.value)"
      />
    } @else if (model.type === 'dateRange' || model.type === 'datetimeRange') {
      <chitin-daterange-picker
        size="small"
        placeholder="Select Date(s)"
        color="secondary"
        [addDateRangePresets]="['YTD']"
        [hideDateRangePresets]="['This Year']"
        [showLabelContextInfo]="true"
        [fullwidth]="true"
        [value]="$any(model.value)"
        (valueChange)="onValueChanged($event)"
      />
    } @else if (model.type === 'isOneOf') {
      <chitin-chips-input
        size="small"
        [clearable]="false"
        placeholder="value1,value2,..."
        [value]="$any(model.value)"
        [emitValueTrigger]="chipsForceEmitTrigger.asObservable()"
        (emitValueTriggerFinished)="forceEmitFinished($event)"
        (valueChange)="onValueChanged($event)"
      />
    }
    @if (
      model.type !== 'range' &&
      model.type !== 'dateRange' &&
      model.type !== 'datetimeRange' &&
      model.type !== 'exists' &&
      model.type !== 'nexists' &&
      model.type !== 'isOneOf' &&
      model.type !== 'in' &&
      model.type !== 'monthDaySelect'
    ) {
      <chitin-form-input
        size="small"
        [clearable]="false"
        placeholder="value"
        [value]="$any(model.value)"
        (valueChange)="onValueChanged($event)"
      />
    }
  </div>
}
