@if (flagsCount() > 0) {
  <div class="scanStatus bg-success text-white d-flex align-items-center gap-3 px-3 py-2 mb-2">
    <div class="flex-grow-1 d-flex align-items-center gap-2">
      <chitin-icon icon="fa-solid fa-circle-info" style="font-size: 1.3rem" />
      <div>Action taken</div>
    </div>
    <div class="flex-grow-1 fw-bold">{{ flagsIdentity() }}</div>
    <div class="scanStatusDivider"></div>
    @if (economicValue()) {
      <div class="fw-bold" style="flex-grow: 0.5">{{ economicValue() | currency }}</div>
      <div class="scanDescription">{{ flagsValueDescription() }}</div>
    } @else {
      <div style="width: 45%"></div>
    }
  </div>
}

<div class="card mb-3">
  <div class="card-body">
    <div class="d-flex">
      <div style="min-width: 220px">
        <div class="d-flex justify-content-between gap-4">
          <div class="fw-bold">Scan Record ID:</div>
          <div class="text-end">{{ scanId() }}</div>
        </div>
        <div class="d-flex justify-content-between gap-4">
          <div class="fw-bold">Conditions Scanned:</div>
          <div class="text-end">{{ flagsScanned() }}</div>
        </div>
        <div class="d-flex justify-content-between gap-4">
          <div class="fw-bold">Detections:</div>
          <div class="text-end">{{ flagsCount() }}</div>
        </div>
      </div>
      <div class="scanVerticalSeparator"></div>
      <div class="d-flex align-items-center gap-3">
        @for (item of flagsCategories(); track $index) {
          @let itemCount = flagsMapFiltered()[item.title]?.length ?? 0;
          <div class="flag-cat-item">
            <div class="flag-cat-count" [style.background]="item.color" [class.active]="itemCount > 0">{{ itemCount }}</div>
            <div class="flag-cat-title">{{ item.title }}</div>
            <div class="flag-cat-desc">{{ itemCount > 0 ? 'Detection' : 'Normal' }}</div>
          </div>
        }
      </div>
    </div>

    @if (flagsCount() > 0) {
      @for (item of flagsCategories(); track $index) {
        @let itemCount = flagsMapFiltered()[item.title]?.length ?? 0;
        @if (itemCount > 0) {
          <div class="scanHorizontalSeparator my-3"></div>
          @let itemFlags = flagsMapFiltered()[item.title];
          @for (flag of itemFlags; track $index; let last = $last) {
            <div class="d-flex gap-2">
              <div [style.background]="item.color" style="width: 3px"></div>
              <div class="py-2" style="flex-grow: 1">
                <div class="fw-bold">{{ flag.title }}</div>
                @if (flag.description) {
                  <div style="margin-top: -5px">{{ flag.description }}</div>
                }
              </div>
            </div>

            @if (!last) {
              <div class="scanHorizontalSeparator my-3"></div>
            }
          }
        }
      }
    }
  </div>
</div>
